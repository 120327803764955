
export const data = [
    { 
        title: '',
        description: '',
        btnXPos: 100,
        btnYPos: 204,
        btnWidth: 554, 
        bthHeight: 372,
        btnSrc: 'img--aerial-1-tmb.jpg',
        btnNode: null,
        enlargedSrc: 'img--aerial-1-enlargement.jpg',
        enlargedNode: null
    },
    { 
        title: '',
        description: '',
        btnXPos: 682,
        btnYPos: 204,
        btnWidth: 554, 
        bthHeight: 372,
        btnSrc: 'img--aerial-2-tmb.jpg',
        btnNode: null,
        enlargedSrc: 'img--aerial-2-enlargement.jpg',
        enlargedNode: null
    },
    { 
        title: '',
        description: '',
        btnXPos: 1268,
        btnYPos: 204,
        btnWidth: 554, 
        bthHeight: 372,
        btnSrc: 'img--aerial-3-tmb.jpg',
        btnNode: null,
        enlargedSrc: 'img--aerial-3-enlargement.jpg',
        enlargedNode: null
    },
    { 
        title: '',
        description: '',
        btnXPos: 100,
        btnYPos: 608,
        btnWidth: 554, 
        bthHeight: 372,
        btnSrc: 'img--aerial-4-tmb.jpg',
        btnNode: null,
        enlargedSrc: 'img--aerial-4-enlargement.jpg',
        enlargedNode: null
    },
    { 
        title: '',
        description: '',
        btnXPos: 682,
        btnYPos: 608,
        btnWidth: 554, 
        bthHeight: 372,
        btnSrc: 'img--aerial-5-tmb.jpg',
        btnNode: null,
        enlargedSrc: 'img--aerial-5-enlargement.jpg',
        enlargedNode: null
    },
    { 
        title: '',
        description: '',
        btnXPos: 1268,
        btnYPos: 608,
        btnWidth: 554, 
        bthHeight: 372,
        btnSrc: 'img--aerial-6-tmb.jpg',
        btnNode: null,
        enlargedSrc: 'img--aerial-6-enlargement.jpg',
        enlargedNode: null
    }
];