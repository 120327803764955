import React from 'react';
import SEO from '../components/seo/SEO';
import ViewAerialPhotos from '../components/aerialPhotos/ViewAerialPhotos';


export default function AerialPhotos ( { location } ) { 


  return (
    <>
      <SEO title='Aerial Photos' />
      <ViewAerialPhotos />
    </>
  );

}
