import React from 'react'
import Img from 'gatsby-image';
import styles from './stylesAerialPhotos.module.scss';


export default function ButtonAerialPhotosItem ( { index, item, selectItem } ) {
  
  return (
    <button 
      className={ styles.btn } 
      style={ { left: item.btnXPos, top: item.btnYPos, width: item.btnWidth, height: item.bthHeight } }
      onClick={ () => selectItem(index) } 
    >
      <Img className={ styles.btnImg } fluid={ item.btnNode.childImageSharp.fluid } alt={ item.title + ' thumbnail' } />
    </button>
  );

}
